export const gtm = () => {
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-M2P4VWM');
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-PK9RKQB');
    const pageWithPrice = document.querySelector('[data-plan-form-url]');

    if (pageWithPrice) {
        const ecommerceItemOneMonth = {
            item_name: '1 month',
            item_id: 'mf1_50_hw',
            currency: pageWithPrice.getAttribute('data-price-currency'),
            price: getPriceNumber(pageWithPrice.getAttribute('data-price-one-month')),
            item_brand: 'Hovervatch',
            item_list_name: 'Prices',
            item_list_id: '/prices',
            quantity: 1,
        };

        const ecommerceItemThreeMonths = {
            item_name: '3 months',
            item_id: 'mf3_84_hw',
            currency: pageWithPrice.getAttribute('data-price-currency'),
            price: getPriceNumber(pageWithPrice.getAttribute('data-price-three-month')),
            item_brand: 'Hovervatch',
            item_list_name: 'Prices',
            item_list_id: '/prices',
            quantity: 1,
        };

        const ecommerceItemTwelveMonths = {
            item_name: '12 months',
            item_id: 'mf12_140_hw',
            currency: pageWithPrice.getAttribute('data-price-currency'),
            price: getPriceNumber(pageWithPrice.getAttribute('data-price-twelve-month')),
            item_brand: 'Hovervatch',
            item_list_name: 'Prices',
            item_list_id: '/prices',
            quantity: 1,
        };

        initPushDataLayerOnViewItemList();
        initPushDataLayerOnAddToCard();

        function initPushDataLayerOnViewItemList() {
            const homePage = document.querySelector('[data-home-page]');
            const pricing = document.querySelector('[data-intersection-observer-pricing]');
            const observerPricing = new IntersectionObserver(function (entries) {
                if (entries[0].intersectionRatio > 0) {
                    pushDataLayer();
                    observerPricing.disconnect();
                }
            });

            if (homePage) {
                observerPricing.observe(pricing);
            } else {
                pushDataLayer();
            }

            function pushDataLayer() {
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    event: 'view_item_list',
                    ecommerce: {
                        items: [ecommerceItemOneMonth, ecommerceItemThreeMonths, ecommerceItemTwelveMonths],
                    },
                });
            }
        }

        function initPushDataLayerOnAddToCard() {
            const planSubmits = document.querySelectorAll('[data-gtm-add-to-cart]');
            if (!planSubmits.length) return;

            [...planSubmits].forEach(function (submit) {
                submit.addEventListener('click', function () {
                    const planMonth = this.dataset.gtmAddToCart;
                    let itemGTM;

                    if (planMonth === '1') {
                        itemGTM = ecommerceItemOneMonth;
                    } else if (planMonth === '3') {
                        itemGTM = ecommerceItemThreeMonths;
                    } else if (planMonth === '12') {
                        itemGTM = ecommerceItemTwelveMonths;
                    }

                    dataLayer.push({ ecommerce: null });

                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        event: 'add_to_cart',
                        ecommerce: {
                            purchase: {
                                items: [itemGTM],
                            },
                        },
                    });
                });
            });
        }

        function getPriceNumber(price) {
            let priceTypeNumber = price.replaceAll(',', '.');
            const priceParts = priceTypeNumber.split('.');

            if (priceParts.length === 3) {
                priceTypeNumber = priceTypeNumber.replace('.', '');
            }

            return Number(priceTypeNumber);
        }
    }
};
