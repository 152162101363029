import { lazyLoadAnalytics } from '../../../../Common/Resources/src_front/js/components/lazyLoadAnalytics';
import { gtm } from './components/analytics/gtm';
import { bingAds } from './components/analytics/bing-ads';
import { pushengage } from './components/analytics/pushengage';
import { initAmplitude } from './components/initAmplitude';

document.addEventListener('DOMContentLoaded', function () {
    lazyLoadAnalytics(gtm);
    lazyLoadAnalytics(bingAds);
    lazyLoadAnalytics(pushengage);
    initAmplitude();
});
