import amplitude from 'amplitude-js';
import { eventProperties } from './amplitudeConfig';
import cloneDeep from 'lodash.clonedeep';

export const initAmplitudeOnSubmit = () => {
    const forms = document.querySelectorAll('[data-amplitude-form-submit]');

    forms.forEach((form) => {
        form.addEventListener('submit', function () {
            const event = this.dataset.amplitudeFormSubmit;
            const formErrors = this.querySelectorAll('[data-amplitude-form-error]:not(.hide)');
            const emailFormErrors = this.querySelectorAll('[data-amplitude-form-error].is-visible');
            const amplitudeProperties = cloneDeep(eventProperties);

            if (forms.length) {
                this.dataset.validationErrorCount = `${+this.dataset.validationErrorCount + 1}`;
                amplitudeProperties.validation_error = '';
            }

            if (this.hasAttribute('data-page-location')) {
                amplitudeProperties.page_location = this.getAttribute('data-page-location');
            }

            formErrors.forEach((error) => {
                amplitudeProperties.validation_error += `${error.textContent.trim()}, `;
                amplitudeProperties.validation_error_count = this.dataset.validationErrorCount;
            });

            emailFormErrors.forEach((error) => {
                amplitudeProperties.validation_error += `${error.textContent.trim()}, `;
                amplitudeProperties.validation_error_count = this.dataset.validationErrorCount;
            });

            amplitude.getInstance().logEvent(event, amplitudeProperties);
        });
    });
};
