import amplitude from 'amplitude-js';
import { initAmplitudeOnLoad } from '../amplitude/initAmplitudeOnLoad';
import { initAmplitudeOnClick } from '../amplitude/initAmplitudeOnClick';
import { initAmplitudeOnSubmit } from '../amplitude/initAmplitudeOnSubmit';

export const initAmplitude = () => {
    const amplitudeApiKey = document.querySelector('[data-amplitude]')?.dataset.amplitude;
    if (!amplitudeApiKey) return;
    amplitude.getInstance().init(amplitudeApiKey);

    initAmplitudeOnLoad();
    initAmplitudeOnClick();
    initAmplitudeOnSubmit();
};
